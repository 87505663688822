import React, { useEffect, useState } from "react";
import "../microsite/microsite.css";
import "../../css/emailTemplate.css";
import { useTranslation } from "react-i18next";

import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from "react-redux";
import {
  getStarterKitEmailTemplates,
  addCustomerEmailTemplate
} from "apiService/RESTApi";

export default function CustomerEmailTemplate() {
  const { t, i18n } = useTranslation("translations");
  const [currentContent, setCurrentContent] = useState('<p>Welcome to the customer email editor!</p>');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('emailTemplates');
  const [templates, setTemplates] = useState([]);
  const { groups, accountDetails, roAdmin } = useSelector((state) => state.loginReducer);
  const apiKey = "b4lgxxwmo5l16zsrltnpndqtc2lahoxwqy5d4rfgcomvd0d2"
  const editorRef = useRef(null);

  useEffect(() => {
    const fetchStarterKitTemplates = async () => {
      try {
        // group[0] gets accountID which is the customer uuid
        const customerUuid = groups.length > 0 ? groups[0] : null;
        const response = await getStarterKitEmailTemplates()

        // const data = await response.json();
        const data = response.data.response.data;
        setTemplates(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching templates:", error);
        setLoading(false);
      }
    };

    fetchStarterKitTemplates();
  }, []);

  const handleTemplateClick = (content) => {
    setCurrentContent(content);
  };

   const saveTemplate = async () => {
    console.log("click save")
    if (editorRef.current) {
      console.log(editorRef.current.getContent());

      try {
        // group[0] gets accountID which is the customer uuid
        const customerUuid = groups.length > 0 ? groups[0] : null;
        const payload = {
          customerUuid: customerUuid,
          name: "name",
          subject: "subject",
          body: editorRef.current.getContent()
      };
        const response = await addCustomerEmailTemplate(payload)

        // const data = await response.json();
        const data = response.data.response.data;
        setTemplates(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching templates:", error);
        setLoading(false);
      }
    };
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>

      {/* Editor Section */}
      <div style={{ flex: 1, padding: '20px', backgroundColor: '#f4f4f4' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Customer Email Template</h2>
          <div className="test-button">
            <button>Test</button>
          </div>
          <div>
            <button onClick={saveTemplate} style={{ marginRight: '10px' }}>Save</button>
            <button>Preview</button>
          </div>
        </div>

        <div style={{ marginTop: '20px', backgroundColor: '#fff', padding: '20px', borderRadius: '5px' }}>
          <Editor
            apiKey={apiKey}
            onInit={(_evt, editor) => editorRef.current = editor}
            initialValue={currentContent}
            init={{
              plugins: [
                'autolink', 'link', 'lists', 'image', 'table', 'wordcount',
              ],
              menubar: false,
              toolbar: 'undo redo | bold italic underline | link image | align numlist bullist',
              content_style: `
                body {
                  font-family: Arial, sans-serif; 
                  font-size: 14px; 
                  text-align: center; 
                  margin: 0 auto; 
                  max-width: 600px; 
                }
                img {
                  max-width: 100%;
                  height: auto;
                }
                p {
                  margin: 20px 0;
                }
              `,
            }}
            // onEditorChange={(content) => setCurrentContent(content)}
          />
        </div>
      </div>

      <div style={{ width: '300px', backgroundColor: '#f8f9fb', borderLeft: '1px solid #ddd', padding: '0' }}>

        {/* Tabs Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#e6efff',
            padding: '10px 20px',
            borderBottom: '1px solid #ddd',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              borderBottom: activeTab === 'emailTemplates' ? '2px solid #1a73e8' : 'none',
              flex: 1,
              paddingBottom: '5px',
            }}
            onClick={() => setActiveTab('emailTemplates')}
          >
            <img
              src="https://w7.pngwing.com/pngs/781/727/png-transparent-computer-icons-document-template-requirements-icon-template-angle-text.png"
              alt="Email Templates"
              style={{ width: '20px', height: '20px', display: 'block', margin: '0 auto 5px' }}
            />
            <div style={{ fontSize: '12px', fontWeight: activeTab === 'emailTemplates' ? 'bold' : 'normal' }}>
              Email Templates
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              borderBottom: activeTab === 'webChat' ? '2px solid #1a73e8' : 'none',
              flex: 1,
              paddingBottom: '5px',
            }}
            onClick={() => setActiveTab('webChat')}
          >
            <img
              src="https://static.thenounproject.com/png/4411488-200.png"
              alt="Web Chat"
              style={{ width: '20px', height: '20px', display: 'block', margin: '0 auto 5px' }}
            />
            <div style={{ fontSize: '12px', fontWeight: activeTab === 'webChat' ? 'bold' : 'normal' }}>
              Web Chat
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              borderBottom: activeTab === 'settings' ? '2px solid #1a73e8' : 'none',
              flex: 1,
              paddingBottom: '5px',
            }}
            onClick={() => setActiveTab('settings')}
          >
            <img
              src="https://w7.pngwing.com/pngs/257/93/png-transparent-settings-gear-icon-gear-configuration-set-up-thumbnail.png" // Settings Icon
              alt="Settings"
              style={{ width: '20px', height: '20px', display: 'block', margin: '0 auto 5px' }}
            />
            <div style={{ fontSize: '12px', fontWeight: activeTab === 'settings' ? 'bold' : 'normal' }}>
              Settings
            </div>
          </div>
        </div>

        {/* Tab Content */}
        <div style={{ padding: '20px' }}>
          {activeTab === 'emailTemplates' && (
            <div>
              {templates.map((template) => (
                <div
                  key={template.id}
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '10px',
                    marginBottom: '10px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                  onClick={() => handleTemplateClick(template.body)}
                >
                  {/* Template Details */}
                  <div>
                    <img
                      src={template.assetUrl}
                      alt={template.name}
                      style={{
                        width: '260px',
                        height: '260px',
                        borderRadius: '4px',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    <h4 style={{ margin: '0 0 5px 0', fontSize: '14px', fontWeight: 'bold' }}>{template.title}</h4>
                    <p style={{ margin: '0', fontSize: '12px', color: '#666' }}>Click to preview and edit</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'webChat' && <div><p>Web Chat content goes here...</p></div>}

          {activeTab === 'settings' && <div><p>Settings content goes here...</p></div>}
        </div>
      </div>
    </div>
  );
}